import type { RouteRecordRaw } from 'vue-router'
import { baseRoute } from '@/router/routes/base'

export default {
  getBaseRoutes: (): RouteRecordRaw => {
    return baseRoute
  },

  filterLinks: (menu: RouteRecordRaw): RouteRecordRaw[] => {
    if (!menu.children) {
      return []
    }

    const children: RouteRecordRaw[] = menu.children

    return children.filter((child: RouteRecordRaw) => {
      return (child && child.meta) ? !child.meta.hideInMenu : false
    })
  },
}
