
import { defineComponent, computed, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import MainSidebarItemButton from '@/components/layout/mainSidebar/MainSidebarItemButton.vue'
import MainSidebarItemChild from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChild.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    TmButton,
    TmToggleButtons,
    MainSidebarItemButton,
    MainSidebarItemChild,
  },
  props: {
    activeRoute: {
      type: Boolean,
    },
    item: {
      type: Object,
      required: true,
    },
    withTooltip: {
      type: Boolean,
    },
    dropdownVisible: {
      type: Boolean,
    },
    isHidden: {
      type: Boolean,
    },
    showChildren: {
      type: Boolean,
    },
  },
  emits: ['item-click'],
  setup(props) {
    const route = useRoute()
    const title = props.item.meta.title || props.item.name
    const children = props.item.meta.sidebarRoutes || []
    const isDropdown = computed(() => props.showChildren && !!children.length)
    const isActiveRoute = computed(() => route.fullPath.startsWith(props.item.path, 1))

    const activeTab = ref(children[0]?.tab)

    return {
      route,
      title,
      children,
      isDropdown,
      isActiveRoute,
      hotKey: props.item.meta.hotKey,
      borderTop: props.item.meta.borderTop,
      activeTab,
    }
  },
})
