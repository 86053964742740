
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import MainSidebarItemChildTitle from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChildTitle.vue'
import MainSidebarItemChildLink from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChildLink.vue'
import type { PageContentSidebarItemTypes } from '@/definitions/_layout/pageContent/sidebar/types'

export default defineComponent({
  components: {
    MainSidebarItemChildTitle,
    MainSidebarItemChildLink,
  },
  props: {
    itemType: {
      type: String as PropType<PageContentSidebarItemTypes>,
    },
  },
  setup(props) {
    const childComponent = computed(() => {
      switch (props.itemType) {
        case 'title': return 'main-sidebar-item-child-title'
        default: return 'main-sidebar-item-child-link'
      }
    })
    return {
      childComponent,
    }
  },
})
