import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d03d78d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row items-center" }
const _hoisted_2 = {
  key: 0,
  class: "hot-key ml-2"
}
const _hoisted_3 = { class: "px-4 pb-3" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_main_sidebar_item_button = _resolveComponent("main-sidebar-item-button")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_toggle_buttons = _resolveComponent("tm-toggle-buttons")!
  const _component_main_sidebar_item_child = _resolveComponent("main-sidebar-item-child")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["main-sidebar-item", {
      'main-sidebar-item--hidden': _ctx.isHidden,
      'main-sidebar-item--border-top': _ctx.borderTop,
    }])
  }, [
    _createVNode(_component_main_sidebar_item_button, {
      item: _ctx.item,
      "with-tooltip": _ctx.withTooltip,
      "is-dropdown": _ctx.isDropdown,
      "is-hidden": _ctx.isHidden,
      active: _ctx.dropdownVisible || _ctx.isActiveRoute,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('item-click')))
    }, {
      tooltip: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.hotKey)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.hotKey), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      append: _withCtx(() => [
        (_ctx.isDropdown)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 0,
              name: _ctx.dropdownVisible ? 'tmi-arrow-drop-up-sharp' : 'tmi-arrow-drop-down-sharp',
              class: "sidebar-button__dropdown-arr"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["item", "with-tooltip", "is-dropdown", "is-hidden", "active"]),
    (_ctx.isDropdown && _ctx.dropdownVisible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.children[0].tab)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_tm_toggle_buttons, {
                    class: "w100pr",
                    dark: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (item) => {
                        return (_openBlock(), _createBlock(_component_tm_button, {
                          key: item.tab,
                          class: _normalizeClass(_ctx.activeTab === item.tab ? '_active' : ''),
                          "no-border": "",
                          flat: "",
                          color: "secondary",
                          onClick: ($event: any) => (_ctx.activeTab = item.tab)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.tab), 1)
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.tab
                  }, [
                    (_ctx.activeTab === item.tab)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.children, (child, i) => {
                          return (_openBlock(), _createBlock(_component_main_sidebar_item_child, _mergeProps({ key: i }, child), null, 16))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ], 64))
            : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (child, i) => {
                  return (_openBlock(), _createBlock(_component_main_sidebar_item_child, _mergeProps({ key: i }, child), null, 16))
                }), 128))
              ]))
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}