
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    beta: {
      type: Boolean,
    },
    route: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
})
