
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: { TmTooltip },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
    },
    withTooltip: {
      type: Boolean,
    },
    isDropdown: {
      type: Boolean,
      default: true,
    },
    isHidden: {
      type: Boolean,
    },
  },
  setup(props) {
    const route = useRoute()
    const linkToName = props.item.children
      ? props.item.children[0].name
      : props.item.name
    const isActiveLink = computed(() => {
      return route.fullPath.startsWith(props.item.path, 1)
    })

    return {
      title: props.item.meta.title || props.item.name,
      counter: props.item.meta.counter,
      icon: props.item.meta.icon,
      bigIcon: props.item.meta.bigIcon,
      hotKey: props.item.meta.hotKey,
      linkToName,
      isActiveLink,
    }
  },
})
