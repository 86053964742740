import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d9372be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-sidebar-item-child-link__text" }
const _hoisted_2 = {
  key: 0,
  class: "main-sidebar-item-child-link__beta"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
    class: "main-sidebar-item-child-link",
    "active-class": "main-sidebar-item-child-link--active",
    to: _ctx.route
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
      (_ctx.beta)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Beta"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to"])), [
    [_directive_ripple]
  ])
}