
import type { PropType } from 'vue'
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import MainSidebarItem from '@/components/layout/mainSidebar/MainSidebarItem.vue'
import navigationService from '@/services/navigationService'
import MainSidebarLogo from '@/components/layout/mainSidebar/MainSidebarLogo.vue'
import type { RouteRecordRaw } from 'vue-router'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { mainSidebarCollapsedClass } from '@/definitions/_layout/mainSidebar/data'
import { useMobileNavigation } from '@/compositions/mobileNavigation'
import { useBreakpoints } from '@/compositions/breakpoints'
import MainSidebarToggler from '@/components/layout/mainSidebar/MainSidebarToggler.vue'

type LinkType = RouteRecordRaw & { dropdownVisible: boolean; }

export default defineComponent({
  components: {
    MainSidebarToggler,
    TmTooltip,
    TmScrollbar,
    MainSidebarLogo,
    MainSidebarItem,
  },
  props: {
    isLinksHidden: {
      type: Boolean,
      default: false,
    },
    showChildren: {
      type: Boolean,
      default: false,
    },
    hideLogo: {
      type: Boolean,
      default: false,
    },
    hideMinimizeButton: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Object as PropType<RouteRecordRaw>,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    showSidebarOnHover: {
      type: Boolean,
    },
  },
  setup(props) {
    const route = useRoute()
    const isReady = ref(false)
    const { closeNavigation, updateMenuState } = useMobileNavigation()
    const { isLgMax } = useBreakpoints()
    const prepareLinks = (items: RouteRecordRaw[]): LinkType[] => {
      return items.map((e: RouteRecordRaw) => {
        return {
          ...e,
          dropdownVisible: route.fullPath.startsWith(e.path, 1),
        }
      })
    }
    const menuOpen = ref(props.opened)
    const hoverState = ref(false)
    const mainSidebarOpen = computed(() => props.showSidebarOnHover ? hoverState.value : menuOpen.value)
    const links = ref(prepareLinks(navigationService.filterLinks(props.menu)))

    const handleClick = (item: LinkType) => {
      if (props.showChildren && links.value) {
        links.value = links.value.map((e: LinkType) => {
          const dropdownState = item.dropdownVisible ? false : e === item

          return {
            ...e,
            dropdownVisible: dropdownState,
          }
        })
      }
    }

    const updateBodyClass = () => {
      document.querySelector('body')?.classList.toggle(mainSidebarCollapsedClass, !menuOpen.value)
    }
    const toggleMenu = () => {
      if (isLgMax.value) {
        updateMenuState()
      }
      menuOpen.value = !menuOpen.value
      updateBodyClass()
    }

    onMounted(() => {
      updateBodyClass()
      setTimeout(() => {
        isReady.value = true
      }, 150) // 150ms same time as for transition rule
    })

    onBeforeRouteUpdate(() => {
      closeNavigation()
    })

    watch(
      () => props.opened,
      (newVal: boolean) => {
        menuOpen.value = newVal
      }
    )

    return {
      mainSidebarOpen,
      menuOpen,
      links,
      hoverState,
      handleClick,
      toggleMenu,
      isReady,
    }
  },
})
