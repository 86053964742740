import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05a99612"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-button__text" }
const _hoisted_2 = {
  key: 2,
  class: "sidebar-button__counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-sidebar-item-button sidebar-button", {
      'sidebar-button--active-link': _ctx.isActiveLink,
      'sidebar-button--active': _ctx.active,
      'sidebar-button--hidden': _ctx.isHidden,
      'sidebar-button--dropdown': _ctx.isDropdown,
    }])
  }, [
    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isDropdown ? 'div' : 'router-link'), {
      ref: "link",
      class: "sidebar-button__wrapper",
      to: { name: _ctx.linkToName }
    }, {
      default: _withCtx(() => [
        (_ctx.bigIcon)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 0,
              name: _ctx.icon,
              "custom-size": "28px"
            }, null, 8, ["name"]))
          : (_openBlock(), _createBlock(_component_tm_icon, {
              key: 1,
              name: _ctx.icon,
              size: "xLarge"
            }, null, 8, ["name"])),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
        (_ctx.counter && !_ctx.isHidden)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.counter), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "append", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["to"])), [
      [_directive_ripple]
    ]),
    (_ctx.withTooltip)
      ? (_openBlock(), _createBlock(_component_tm_tooltip, {
          key: 0,
          position: "right",
          delayed: "",
          offset: [14, 10]
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "tooltip", {}, undefined, true)
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 2))
}